import { useState } from 'react';
import { useQuery } from 'react-query'
import Cabecera from '../components/Cabecera'
import Spiner from '../components/Spiner';
import { customSwall } from '../components/CustomSwal';
import { urlServer } from '../config/config';

import '../assets/css/bases.css'
import { getDatos, getProductos } from '../api/getUser';
import TablaProductos from '../components/TablaProductos';
import ModalProducto from '../components/ModalProducto';
import TablaCampanas from '../components/TablaCampanas';
import ModalCampana from '../components/ModalCampana';
import ModalEnvioCampana from '../components/ModalEnvioCampana';

function Buttons({ numMostrar, pagActiva, setPagActiva, totalP }) {

    const paginas = Math.ceil(totalP / numMostrar)
    const rangoPaginas = () => {
        let pags = []
        if (paginas > 7) {
            let inicio = 0;
            if (pagActiva <= 4) {
                inicio = 0;
            } else {
                inicio = pagActiva - 4;
            }
            let fin = 7;
            if (pagActiva > 4) {
                fin = pagActiva + 3;
                if (fin > paginas) {
                    fin = paginas;
                    inicio = paginas - 7;
                }
            }
            for (let p = inicio; p < fin; p++) {
                pags.push(p+1)
            }
        } else {
            for (let p = 0; p < paginas; p++) {
                pags.push(p+1)
            }
        }
        return pags
    }
    const rango = rangoPaginas()

    return(<>
        { paginas > 1 && <>
            { pagActiva > 1 ? <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(pagActiva - 1) }>Anterior</button> : <button className="btn-pag btn-hidden">Anterior</button> }
            {
            rango.map((p, k) => {
                return (<button key={ p } className={ `btn-pag ${ pagActiva === p ? 'btn-primario' : 'btn-cuaternario' }` } onClick={ () => setPagActiva(p) }>{ p }</button>)
            })
            }
            { pagActiva < paginas ? <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(pagActiva + 1) }>Siguiente</button> : <button className="btn-pag btn-hidden">Siguiente</button>}
            {paginas > 7 && <> {pagActiva === paginas ? 
            <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(1) }>Inicio</button> : 
            <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(paginas) }>Ultima</button>
            }</>}
        </>}
    </>)
}

export default function Campanas() {

    const [ modal, setModal ] = useState(false)
    const [ modalEnvio, setModalEnvio ] = useState(false)
    const [ datosModalEnvios, setDatosModalEnvios ] = useState({})
    const [ accionModal, setAccionModal ] = useState('guardar')
    const [ valuesMod, setvaluesMod ] = useState({})
    const [ numMostrar, setNumMostrar ] = useState(20)
    const [ inicio, setInicio ] = useState(0)
    const [ buscarB, setBuscarB ] = useState('')
    const [ pagActiva, setPagActiva ] = useState(1)
    const user = JSON.parse(localStorage.getItem('userData'))
    const { data, isLoading } = useQuery(["campanas"], () => getDatos('/api/campanas/get', { token: user.token, limit: numMostrar, inicio: inicio, buscar: buscarB }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    function clickPagina(p) {
        setInicio(numMostrar * (p-1))
        setPagActiva(p)
    }

    function cambiarMostrar(num) {
        setNumMostrar(num)
        setPagActiva(1)
        setInicio(0)
    }

    function buscar(valor) {
        setPagActiva(1)
        setInicio(0)
        setBuscarB(valor)
    }

    function itUltimaPagina() {
        const ultimaPagina = Math.ceil(data.total / numMostrar)
        clickPagina(ultimaPagina)
    }

    function mostrarModalEnvios(datos) {
        setDatosModalEnvios(datos)
        setModalEnvio(true)
    }

    function modificar(id) {
        let datos = {
            id: id,
            token: user.token
        }
        customSwall.fire({
            title: 'Procesando...',
            html: 'Espera...',
            animation: false,
            backdrop: false,
            didOpen: () => {
                customSwall.showLoading()
                let formData = new FormData()
                for ( var key in datos ) {
                    formData.append(key, datos[key]);
                }
                fetch(`${ urlServer }/api/productos/seleccionar`, {
                    method: 'POST',
                    body: formData,
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.ok) {
                        setAccionModal('modificar')
                        setvaluesMod(data.values)
                        setModal(true)
                        customSwall.fire({
                            text: 'datos listos',
                            backdrop: false,
                            timer: 10,
                            showConfirmButton: false
                        })
                    } else {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: !!data.error ? data.error : 'ocurrio un error',
                            showConfirmButton: true,
                        })
                    }
                })
                .catch(error => {
                    customSwall.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'ocurrio un error, contacta a soporte',
                        showConfirmButton: true,
                    })
                })
            }
          })
    }

    return(<>
        { isLoading && <Spiner/> }
        <Cabecera titulo={'Campañas de email - Branding Studio'} />
        { modal && <ModalCampana cerrar={ () => setModal(false) } irUltimaPagina={ () => itUltimaPagina() } values={ valuesMod } accion={ accionModal } />}
        { modalEnvio && <ModalEnvioCampana cerrar={ () => setModalEnvio(false) } datos={ datosModalEnvios } />}
        <section className="btns-header-base">
        <div className="columna-completa">
            <div className="cont-botones-base">
                <div className='cont-select-num-elementes'>
                    <div className='cont-cong-option'>
                        <label>Mostrar</label>
                        <select onChange={ e => cambiarMostrar(e.target.value) }>
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                            <option>250</option>
                            <option>500</option>
                            <option>1000</option>
                        </select>
                    </div>
                    <div className='cont-cong-option'>
                        <label>Buscar</label>
                        <input type='text' placeholder='Buscar' onChange={ e => buscar(e.target.value) }/>
                    </div>
                </div>
                { user.role === 'admin' && <button onClick={ () => { setAccionModal('guardar'); setvaluesMod({}); setModal(true) } }>Nueva Campaña</button>}
            </div>
        </div>
        </section>
        <section id="cont-table" className="section-header-base tableFixHead">
            <TablaCampanas campanas={ data?.campanas } modificar={ (id) => modificar(id) } enviar={ (datos) => mostrarModalEnvios(datos) } />
        </section>
        <section className="footer-base">
            <div className="cont-footer-table-op">
                <div id="btns-pages" className="cont-btns-pages container f-iz">
                    { !!data && <Buttons numMostrar={ numMostrar } pagActiva={ pagActiva } setPagActiva={ (p) => clickPagina(p) } totalP={ data.total } /> }
                </div>
                <div className="f-dr">
                    { !!data && <p>Mostrando { data.campanas.length } campañas de { data.total } en { Math.ceil(data.total / numMostrar) } páginas</p> }
                </div>
            </div>
        </section>
    </>)
}