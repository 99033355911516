export default function BtnsPages({ numMostrar, pagActiva, setPagActiva, totalP }) {

    const paginas = Math.ceil(totalP / numMostrar)
    const rangoPaginas = () => {
        let pags = []
        if (paginas > 7) {
            let inicio = 0;
            if (pagActiva <= 4) {
                inicio = 0;
            } else {
                inicio = pagActiva - 4;
            }
            let fin = 7;
            if (pagActiva > 4) {
                fin = pagActiva + 3;
                if (fin > paginas) {
                    fin = paginas;
                    inicio = paginas - 7;
                }
            }
            for (let p = inicio; p < fin; p++) {
                pags.push(p+1)
            }
        } else {
            for (let p = 0; p < paginas; p++) {
                pags.push(p+1)
            }
        }
        return pags
    }
    const rango = rangoPaginas()

    return(<>
        { paginas > 1 && <>
            { pagActiva > 1 ? <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(pagActiva - 1) }>Anterior</button> : <button className="btn-pag btn-hidden">Anterior</button> }
            {
            rango.map((p, k) => {
                return (<button key={ p } className={ `btn-pag ${ pagActiva === p ? 'btn-primario' : 'btn-cuaternario' }` } onClick={ () => setPagActiva(p) }>{ p }</button>)
            })
            }
            { pagActiva < paginas ? <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(pagActiva + 1) }>Siguiente</button> : <button className="btn-pag btn-hidden">Siguiente</button>}
            {paginas > 7 && <> {pagActiva === paginas ? 
            <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(1) }>Inicio</button> : 
            <button className="btn-pag btn-terceario" onClick={ () => setPagActiva(paginas) }>Ultima</button>
            }</>}
        </>}
    </>)
}