import { Link } from "react-router-dom";

export default function Cabecera({ titulo }) {

    function cerrar() {
        window.localStorage.removeItem('userData');
        window.location.reload(false)
    }

    return (
        <header>
            <h3>{ titulo }</h3>
            <nav>
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><button onClick={ cerrar }>Cerrar sesion</button></li>
                </ul>
            </nav>
        </header>
    )
}