import { Navigate } from "react-router-dom"
import { validToken } from "../api/validarDatos";

export default function ProtectedRoutes ({ children, redirecTo='/' }) {
    const user = !!localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    if (user === null) {
        return <Navigate to={ redirecTo }/>;
    }
    validToken(user.token)
    
    return children;
}