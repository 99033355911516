import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import moment from "moment/moment";
import { customSwall } from './CustomSwal'
import { updateClients } from '../api/getUser';
import Spiner from './Spiner';
import { urlServer } from '../config/config';

export default function ModalCliente({ cerrar, irUltimaPagina, values, accion }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: !!values.id ? values.id : 0,
            fecha_llamada: !!values.fecha_llamada ? moment(values.fecha_llamada).format('Y-M-D') : moment().format('Y-M-D'),
            correo: !!values.correo ? values.correo : '',
            nombre: !!values.nombre ? values.nombre : '',
            apellido: !!values.apellido ? values.apellido : '',
            empresa: !!values.empresa ? values.empresa : '',
            cargo: !!values.cargo ? values.cargo : '',
            celular: !!values.celular ? values.celular : '',
            fijo: !!values.fijo ? values.fijo : '',
            observaciones: !!values.observaciones ? values.observaciones : '',
            llamada: !!values.llamada ? values.llamada : false,
            token: !!user.token ? user.token : ''
        },
    })
    const { mutate, isLoading } = useMutation(updateClients, {
        onSuccess: (data) => {
            if (data.ok) {
                cerrar()
                customSwall.fire({
                    icon: 'success',
                    title: `Cliente actualizado!`,
                    text: `El cliente fue actualizado correctamente`,
                    showConfirmButton: true,
                }).then( response => {
                    queryClient.invalidateQueries(["clientes"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function successGuardado() {
        queryClient.invalidateQueries(["clientes"])
        irUltimaPagina()
        cerrar()
    }

    const onSubmit = handleSubmit(datos => {
        if (accion === 'guardar') {
            customSwall.fire({
                title: 'Procesando...',
                html: 'Espera...',
                animation: false,
                backdrop: false,
                didOpen: () => {
                    customSwall.showLoading()
                    let formData = new FormData()
                    for ( var key in datos ) {
                        formData.append(key, datos[key]);
                    }
                    fetch(`${ urlServer }/api/base/clientes/nuevo`, {
                        method: 'POST',
                        body: formData,
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.ok) {
                            customSwall.fire({
                                icon: 'success',
                                title: `Cliente guardado!`,
                                text: `El cliente fue guardado correctamente`,
                                showConfirmButton: true,
                            }).then(response => {
                                successGuardado()
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'ocurrio un error, contacta a soporte',
                            showConfirmButton: true,
                        })
                    })
                }
              })
        } else {
            mutate(datos)
        }
    })

    return(
        <section className="section-form-base">
            { isLoading && <Spiner/> }
            <form onSubmit={ onSubmit }>
                <div className="cont-acciones-form-base">
                    <button type='submit' className='btn btn-ok'>Guardar</button>
                    <button onClick={ cerrar } className='btn btn-cancel'>Cancelar</button>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="nombre">Nombre:</label>
                        <input type="text" placeholder="Juan" { ...register('nombre') }/>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="apellido">Apellido:</label>
                        <input type="text" placeholder="Martinez" { ...register('apellido') }/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="empresa">Empresa:</label>
                        <input type="text" placeholder="Coca-Cola" { ...register('empresa') }/>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="cargo">Cargo:</label>
                        <input type="text" placeholder="Gerente de Marketing" { ...register('cargo') }/>
                    </div>
                </div>
                <div className="cont-form-item">
                    <label htmlFor="correo">Correo:</label>
                    <input type="email" placeholder="juan@coca-cola.com" { ...register('correo', {
                            required: {
                                value: true,
                                message: 'El correo es obligatorio'
                            },
                        }) }/>
                        <span>{ !!errors['correo'] && errors['correo'].message }</span>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="celular">Celular:</label>
                        <input type="text" placeholder="0985820000" { ...register('celular') }/>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="fijo">Fijo:</label>
                        <input type="text" placeholder="25589634" { ...register('fijo') }/>
                    </div>
                </div>
                <div className="cont-form-item">
                    <label htmlFor="observaciones">Observaciones:</label>
                    <textarea cols="30" rows="10" { ...register('observaciones') }></textarea>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="llamada">Llamada realizada:</label>
                        <input type="checkbox" { ...register('llamada') }/>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="fecha_llamada">Fecha de llamada:</label>
                        <input type="date" { ...register('fecha_llamada') }/>
                    </div>
                </div>
            </form>
        </section>
    )
}