import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { customSwall } from './CustomSwal'
import { getDatos, mutarDatos } from '../api/getUser';
import Spiner from './Spiner';
import { urlServer } from '../config/config';
import SelectMailsPopup from './SelectMailsPopup';
import { useState } from 'react';

export default function ModalCampana({ cerrar, values, accion }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const [ popUpMails, setPopUpMails ] = useState(false)

    const { data: plantillas = [], isLoading: recuperandoPlantillas } = useQuery(["plantillasEmail"], () => getDatos('/api/get-data/getPlantillasMail', { token: user.token }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    const {  data: datosAgentes = { agentes: [] }, isLoading: recuperandoAgentes } = useQuery(["agentes"], () => getDatos('/api/mail-config-user', { token: user.token }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    const { register, handleSubmit, setError, clearErrors, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            id: !!values.id ? values.id : 0,
            nombre: !!values.nombre ? values.nombre : '',
            plantilla: !!values.plantilla ? values.plantilla : '',
            plantilla: !!values.agente ? values.agente : '',
            base: {
                mails: [],
                type: 'selected', //exluded
                totalBase: 0
            },
            token: !!user.token ? user.token : ''
        },
    })
    const { mutate, isLoading } = useMutation(mutarDatos('api/campanas/edit', {}), {
        onSuccess: (data) => {
            if (data.ok) {
                cerrar()
                customSwall.fire({
                    icon: 'success',
                    title: `Campaña actualizada!`,
                    text: `Se actualizó la campaña`,
                    showConfirmButton: true,
                }).then( response => {
                    queryClient.invalidateQueries(["campanas"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function successGuardado() {
        queryClient.invalidateQueries(["campanas"])
        //irUltimaPagina()
        cerrar()
    }

    function setearMails(data) {
        setValue('base', data)
        setPopUpMails(false)
        if (data.mails.length === 0 && data.type === 'selected') {
            setError("base", { type: "custom", message: "Selecciona por lo menos 1 correo para que la campaña sea válida" })
        } else {
            clearErrors('base')
        }
    }

    const onSubmit = handleSubmit(datos => {
        if (datos.base.mails.length === 0 && datos.base.type === 'selected') {
            setError("base", { type: "custom", message: "Selecciona por lo menos 1 correo para que la campaña sea válida" })
            return
        }
        if (accion === 'guardar') {
            customSwall.fire({
                title: 'Procesando...',
                html: 'Espera...',
                animation: false,
                backdrop: false,
                didOpen: () => {
                    customSwall.showLoading()
                    let formData = new FormData()
                    for ( var key in datos ) {
                        if (key === 'base') {
                            formData.append(key, JSON.stringify(datos[key]));
                        } else {
                            formData.append(key, datos[key]);
                        }
                    }
                    fetch(`${ urlServer }/api/campanas/new`, {
                        method: 'POST',
                        body: formData,
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.ok) {
                            customSwall.fire({
                                icon: 'success',
                                title: `Campaña creada!`,
                                text: `Se ha creado la campaña ${ watch('nombre') }`,
                                showConfirmButton: true,
                            }).then(response => {
                                successGuardado()
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'ocurrio un error, contacta a soporte',
                            showConfirmButton: true,
                        })
                    })
                }
              })
        } else {
            mutate(datos)
        }
    })

    return(
        <section className="section-form-base">
            { isLoading && <Spiner/> }
            { popUpMails && <SelectMailsPopup cancelar={ () => setPopUpMails(false) } mailsSelected={ (data) => setearMails(data) } dataPrev={ watch('base') } />}
            <form onSubmit={ onSubmit }>
                <div className="cont-acciones-form-base">
                    <button type='submit' className='btn btn-ok'>Guardar</button>
                    <button onClick={ cerrar } className='btn btn-cancel'>Cancelar</button>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="nombre">Nombre de la campaña:</label>
                        <input type="text" placeholder="Mesa Stand" { ...register('nombre', {
                                required: {
                                    value: true,
                                    message: 'El nombre de la campaña es obligatorio'
                                },
                            }) }/>
                        <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="precio_m2">Plantilla:</label>
                        <select { ...register('plantilla', {
                            required: {
                                value: true,
                                message: 'Elige la plantilla para esta campaña'
                            },
                        }) } >
                            <option value="">Seleccione una plantilla</option>
                            {
                                plantillas.map( plantilla => <option key={ plantilla.id } value={ plantilla.id }>{ plantilla.nombre }</option>)
                            }
                        </select>
                        <span>{ !!errors['plantilla'] && errors['plantilla'].message }</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="precio_m2">Agente:</label>
                        <select { ...register('agente', {
                            required: {
                                value: true,
                                message: 'Elige el correo desde el cual se enviará esta campaña'
                            },
                        }) } >
                            <option value="">Seleccione un agente</option>
                            {
                                datosAgentes.agentes?.map(agente => {
                                    return(<option key={ agente.id } value={ agente.id }>{ agente.correo }</option>)
                                })
                            }
                        </select>
                        <span>{ !!errors['agente'] && errors['agente'].message }</span>
                    </div>
                </div>
                <button type='button'  className='pp-mail-btn btn-azul' onClick={ () => setPopUpMails(true) }>Lista de correos: { watch('base').type === 'selected' ? watch('base').mails.length : watch('base').totalBase - watch('base').mails.length } Seleccionados</button>
                <span className='error-list-mails'>{ !!errors['base'] && errors['base'].message }</span>
            </form>
        </section>
    )
}