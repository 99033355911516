import { useState, useEffect } from "react";

function useUserData (url, token, parametros={}) {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    useEffect(() => {
        setLoading(true);
        let formData = new FormData();
        formData.append('token', token);
        for ( var key in parametros ) {
            formData.append(key, parametros[key]);
        }
        fetch(url, {
            method: 'POST',
            body: formData,
        })
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }, []);

    return { data, loading, error };
}

export { useUserData }