import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import moment from "moment/moment";
import { customSwall } from './CustomSwal'
import { updateClients, updateProducts } from '../api/getUser';
import Spiner from './Spiner';
import { urlServer } from '../config/config';
import noImage from '../assets/images/add-image.webp'

export default function ModalProducto({ cerrar, irUltimaPagina, values, accion }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            id: !!values.id ? values.id : 0,
            nombre: !!values.nombre ? values.nombre : '',
            descripcion: !!values.descripcion ? values.descripcion : '',
            precio: !!values.precio ? values.precio : 0,
            precio_m2: !!values.precio_m2 ? values.precio_m2 : 0,
            imageProduct: !!values.imagen ? values.imagen : '',
            token: !!user.token ? user.token : ''
        },
    })
    const { mutate, isLoading } = useMutation(updateProducts, {
        onSuccess: (data) => {
            if (data.ok) {
                cerrar()
                customSwall.fire({
                    icon: 'success',
                    title: `Cliente actualizado!`,
                    text: `El cliente fue actualizado correctamente`,
                    showConfirmButton: true,
                }).then( response => {
                    queryClient.invalidateQueries(["Productos"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function successGuardado() {
        queryClient.invalidateQueries(["Productos"])
        irUltimaPagina()
        cerrar()
    }

    function isAnimatedGif(src) {
        return new Promise((resolve ,reject)=>{
            var request = new XMLHttpRequest();
            request.open('GET', src, true);
            request.addEventListener('load', function () {
                if(request.response.indexOf("ANMF") != -1){
                    resolve(1);
                }
                else{
                    resolve(0);
                }
            });
            request.send();
        });
    }

    function addImage(index) {
        let inputFile = document.getElementById("input-fotos"+index);
        let canvas = document.getElementById("canvas");
        inputFile.click();
        inputFile.onchange = function () {

            const [file] = this.files
            let fileUrl;

            if (file) {
                fileUrl = URL.createObjectURL(file);
                isAnimatedGif(fileUrl).then(res => {
                    if (!res) {
                        const image = new Image();
                        image.onload = () => {
                            let w = document.getElementsByClassName('img-prodcuto-form')[0].offsetWidth;
            
                            canvas.width = w;
                            canvas.height = w;
            
                            const ladoMasPeque = Math.min(image.naturalWidth, image.naturalHeight);
            
                            let dist = (Math.max(image.naturalWidth, image.naturalHeight) -  Math.min(image.naturalWidth, image.naturalHeight)) / 2;
            
                            let x = (image.width > image.height) ? dist : 0;
                            let y = (image.width < image.height) ? dist : 0;
            
                            const ctx = canvas.getContext('2d');
            
                            var sourceX = x;
                            var sourceY = y;
                            var sourceWidth = ladoMasPeque;
                            var sourceHeight = ladoMasPeque;
                            var destWidth = w//sourceWidth;
                            var destHeight = w//sourceHeight;
                            var destX = 0//canvas.width / 2 - destWidth / 2;
                            var destY = 0//canvas.height / 2 - destHeight / 2;
            
                            ctx.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
                            const dataURL = canvas.toDataURL('image/png');
                            setValue('imageProduct', dataURL)
                        };
                        image.src = fileUrl;
                    } else {
                        inputFile.value = ''
                        setValue('imageProduct', '')
                        customSwall.fire({
                            title: 'Error!',
                            text: 'Ésta imagen no está soportada',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }).then( _ => {return});
                    }

                });
            } else {
                inputFile.value = ''
                setValue('imageProduct', '')

            }
        }
    }

    const onSubmit = handleSubmit(datos => {
        console.log(datos)
        if (accion === 'guardar') {
            customSwall.fire({
                title: 'Procesando...',
                html: 'Espera...',
                animation: false,
                backdrop: false,
                didOpen: () => {
                    customSwall.showLoading()
                    let formData = new FormData()
                    for ( var key in datos ) {
                        if (key === "file1" || key === "file2" || key === "file3" || key === "file4" || key === "file5") {
                            formData.append(key, datos[key][0]);
                        } else {
                            formData.append(key, datos[key]);
                        }
                    }
                    fetch(`${ urlServer }/api/productos/new`, {
                        method: 'POST',
                        body: formData,
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.ok) {
                            customSwall.fire({
                                icon: 'success',
                                title: `producto guardado!`,
                                text: `El producto fue guardado correctamente`,
                                showConfirmButton: true,
                            }).then(response => {
                                successGuardado()
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'ocurrio un error, contacta a soporte',
                            showConfirmButton: true,
                        })
                    })
                }
              })
        } else {
            mutate(datos)
        }
    })

    return(
        <section className="section-form-base">
            { isLoading && <Spiner/> }
            <form onSubmit={ onSubmit }>
                <div className="cont-acciones-form-base">
                    <button type='submit' className='btn btn-ok'>Guardar</button>
                    <button onClick={ cerrar } className='btn btn-cancel'>Cancelar</button>
                </div>
                <div className='cont-form-item'>
                    <input hidden type="file" id="input-fotos1" accept="image/png, image/jpeg, image/webp" {...register("file1")} />
                    <div className='cont-prew-image-form-product'>
                        <canvas hidden id="canvas"></canvas>
                        <div className='img-prodcuto-form' style={{ backgroundImage: `url("${ watch('imageProduct') === '' ? noImage : watch('imageProduct') }")` }} onClick={ () => addImage(1) }></div>
                    </div>
                </div>
                <div className="cont-form-item">
                    <label htmlFor="nombre">Nombre del producto:</label>
                    <input type="text" placeholder="Mesa Stand" { ...register('nombre', {
                            required: {
                                value: true,
                                message: 'El nombre del producto es obligatorio'
                            },
                        }) }/>
                        <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label htmlFor="precio">Precio:</label>
                        <input type="number" placeholder="40" { ...register('precio') }/>
                    </div>
                    <div className="cont-form-item">
                        <label htmlFor="precio_m2">Precio M2:</label>
                        <input type="number" placeholder="30" { ...register('precio_m2') }/>
                    </div>
                </div>
                <div className="cont-form-item">
                    <label htmlFor="descripcion">Descrición del producto:</label>
                    <textarea cols="30" rows="10" { ...register('descripcion') }></textarea>
                </div>
            </form>
        </section>
    )
}