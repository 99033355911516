import { useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { customSwall } from './CustomSwal'
import Spiner from './Spiner';
import { urlServer } from '../config/config';

export default function ModalEnvioCampana({ cerrar, datos }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: datos.id,
            numEnvios: 15,
            token: !!user.token ? user.token : ''
        },
    })

    const onSubmit = handleSubmit(datos => {
        customSwall.fire({
            title: 'Procesando...',
            html: 'Espera...',
            animation: false,
            backdrop: false,
            didOpen: () => {
                customSwall.showLoading()
                let formData = new FormData()
                for ( var key in datos ) {
                    formData.append(key, datos[key]);
                }
                fetch(`${ urlServer }/api/campanas/envio`, {
                    method: 'POST',
                    body: formData,
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.ok) {
                        customSwall.fire({
                            icon: 'success',
                            title: `Procesado!`,
                            html: data.html,
                            showConfirmButton: true,
                        }).then(response => {
                            queryClient.invalidateQueries(["campanas"])
                            cerrar()
                        })
                    } else {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: !!data.error ? data.error : 'ocurrio un error',
                            showConfirmButton: true,
                        })
                    }
                })
                .catch(error => {
                    customSwall.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'ocurrio un error, contacta a soporte',
                        showConfirmButton: true,
                    })
                })
            }
            })
    })

    return(
        <section className="section-form-base">
            <form onSubmit={ onSubmit } style={{ width: '300px' }}>
                    <h2>{ datos.nombre }</h2>
                    <div className="cont-form-item">
                        <label htmlFor="nombre">Numero de envíos:</label>
                        <input type="number" defaultValue={ 15 } placeholder="15" { ...register('numEnvios', {
                            required: {
                                value: true,
                                message: 'Campo requerido',
                            },
                        }) }/>
                        <span>{ !!errors['numEnvios'] && errors['numEnvios'].message }</span>
                    </div>
                <div className="cont-acciones-form-base" style={{ justifyContent: 'center' }}>
                    <button type='submit' className='btn btn-ok'>Enviar</button>
                    <button onClick={ cerrar } className='btn btn-cancel'>Cancelar</button>
                </div>
            </form>
        </section>
    )
}