import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom';
import { delProduct, mutarDatos } from '../api/getUser';
import { customSwall } from "./CustomSwal"
import Spiner from './Spiner';
import moment from 'moment';
import 'moment/locale/es';

function ItemCampana({ campana, modificar, eliminar, modalEnvio }) {
    const user = JSON.parse(localStorage.getItem('userData'))   

    function showEliminar() {
        customSwall.fire({
            icon: 'question',
            title: `Eliminar Campaña?`,
            html: `<p>Estas seguro? esta acción no se puede revertir</p><br><br><p><b>Eliminar: </b>${ campana.nombre }</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar'
        }).then( response => {
            if (response.isConfirmed) {
                eliminar({token: user.token, campana: campana.id})
            }
        })
    }

    const ultimoCorreo = JSON.parse(campana.ultimo_envio).correo ?? 'Ninguno'
    const ultimaFecha = ultimoCorreo !== 'Ninguno' ? ` - ${moment(JSON.parse(campana.ultimo_envio).fecha_envio).startOf().fromNow()}` : ''

    return (<>
        {<tr>
            <td>{ campana.id }</td>
            <td>{ campana.nombre }</td>
            <td>{ campana.nombrePlantilla }</td>
            <td>{ campana.agenteCorreo }</td>
            <td>{ `${ ultimoCorreo }${ ultimaFecha }` }</td>
            <td className='campanas-proceso-td'>
                <div className='progreso-barra'>
                    <div className='progress-bar-envio'>
                        <div className='progress-bar-envio-color' style={{ width: `${ (campana.procesados * 100) /campana.total }%` }}></div>
                    </div>
                    <p>{`${ Math.round((campana.procesados * 100) /campana.total) }%`}</p>
                </div>
                <div className='proceso-detalles'>
                    <p>Base: { campana.total }</p>
                    <p>Enviados: { campana.procesados }</p>
                </div>
            </td>
            <td className="td-acciones">
                <button className='campanas-nuevo-envio-btn' onClick={ () => modalEnvio({ id: campana.id, nombre: campana.nombre }) }>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                    Nuevo envío
                </button>
                { user.role === 'admin' && <button onClick={ showEliminar } >
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                </button> }
            </td>
        </tr>}
    </>)
}

export default function TablaCampanas({ campanas, modificar, enviar }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) =>mutarDatos('/api/campanas/eliminar', datos)
    })

    function eliminar(datos) {
        mutate(datos, {
            onSuccess: (data) => {
                if (data.ok) {
                    customSwall.fire({
                        icon: 'success',
                        title: `Campaña eliminada!`,
                        text: `Los datos fue eliminado correctamente`,
                        showConfirmButton: true,
                    }).then( response => {
                        queryClient.invalidateQueries(["campanas"])
                    })
                } else {
                    customSwall.fire({
                        icon: 'error',
                        title: 'Error',
                        text: !!data.error ? data.error : 'No se pudo completar la acción, contacta a soporte',
                        showConfirmButton: true,
                    })
                }
            },
            onError: e => {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'ocurrio un error, contacta a soporte',
                    showConfirmButton: true,
                })
                console.error(e)
            }
        })
    }
   
    return(<>
        { isLoading && <Spiner/> }
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Plantilla</th>
                    <th>Agente</th>
                    <th>Último envío</th>
                    <th>Proceso</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                { //console.log(campanas)
                    campanas?.map((campana, key) => {
                        return (
                            <ItemCampana key={ `campana_${ key }` } campana={ campana } modificar={ modificar } eliminar={ (datos) => eliminar(datos) } modalEnvio={ enviar } />
                        )
                    })
                }
            </tbody>
        </table>
    </>)
}