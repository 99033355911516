import { useMutation, useQuery, useQueryClient } from "react-query";
import Spiner from "../components/Spiner";
import { customSwall } from "../components/CustomSwal";
import { getProductos, mutarDatos } from "../api/getUser";
import Cabecera from "../components/Cabecera";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useRef, useState } from "react";
import noImage from '../assets/images/add-image.webp'

export default function AddCotizacion() {

    const queryClient = useQueryClient()
    const [params, setParams] = useSearchParams();
    const [items, setItems] = useState([])

    const inputCantidadItem = useRef();
    const inputPrecioItem = useRef();
    const inputPrecioM2Item = useRef();
    const inputProductoItem = useRef();
    const inputDescripcionItem = useRef();

    const user = JSON.parse(localStorage.getItem('userData'))
    const { data: listaproductos = {} } = useQuery(["Productos"], () => getProductos(user.token), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            email: params.get('email') ?? '',
            empresa: params.get('empresa') ?? '',
            nombreCliente: params.get('name') ?? '',
            apellidoCliente: params.get('last_name') ?? '',
            total: 0,
            imageProduct : ''
        },
    })

    const { mutate, isLoading } = useMutation(mutarDatos, {
        onSuccess: (data) => {
            if (data.ok) {
                customSwall.fire({
                    icon: 'success',
                    title: `Cliente eliminado!`,
                    text: `Los datos fue eliminado correctamente`,
                    showConfirmButton: true,
                }).then(response => {
                    queryClient.invalidateQueries(["Cotizaciones"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'No se pudo completar la acción, contacta a soporte',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function formatearMoneda(valor) {
        const formatter = new Intl.NumberFormat('es-EC', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        return formatter.format(valor);
    }

    function isAnimatedGif(src) {
        return new Promise((resolve ,reject)=>{
            var request = new XMLHttpRequest();
            request.open('GET', src, true);
            request.addEventListener('load', function () {
                if(request.response.indexOf("ANMF") != -1){
                    resolve(1);
                }
                else{
                    resolve(0);
                }
            });
            request.send();
        });
    }

    function addImage(index) {
        let inputFile = document.getElementById("input-foto");
        let canvas = document.getElementById("canvas");
        inputFile.click();
        inputFile.onchange = function () {

            const [file] = this.files
            let fileUrl;

            if (file) {
                fileUrl = URL.createObjectURL(file);
                isAnimatedGif(fileUrl).then(res => {
                    if (!res) {
                        const image = new Image();
                        image.onload = () => {
                            let w = document.getElementsByClassName('img-prodcuto-form')[0].offsetWidth;
            
                            canvas.width = w;
                            canvas.height = w;
            
                            const ladoMasPeque = Math.min(image.naturalWidth, image.naturalHeight);
            
                            let dist = (Math.max(image.naturalWidth, image.naturalHeight) -  Math.min(image.naturalWidth, image.naturalHeight)) / 2;
            
                            let x = (image.width > image.height) ? dist : 0;
                            let y = (image.width < image.height) ? dist : 0;
            
                            const ctx = canvas.getContext('2d');
            
                            var sourceX = x;
                            var sourceY = y;
                            var sourceWidth = ladoMasPeque;
                            var sourceHeight = ladoMasPeque;
                            var destWidth = w//sourceWidth;
                            var destHeight = w//sourceHeight;
                            var destX = 0//canvas.width / 2 - destWidth / 2;
                            var destY = 0//canvas.height / 2 - destHeight / 2;
            
                            ctx.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
                            const dataURL = canvas.toDataURL('image/png');
                            setValue('imageProduct', dataURL)
                        };
                        image.src = fileUrl;
                    } else {
                        inputFile.value = ''
                        setValue('imageProduct', '')
                        customSwall.fire({
                            title: 'Error!',
                            text: 'Ésta imagen no está soportada',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }).then( _ => {return});
                    }

                });
            } else {
                inputFile.value = ''
                setValue('imageProduct', '')

            }
        }
    }

    const onSubmit = handleSubmit(data => {
        console.log(data)
    })

    function addItemToList() {

        if (inputProductoItem.current.value.toString() === '0') {
            return
        }

        let newItems = items
        const indice = listaproductos.productos.findIndex((elemento) => elemento.id.toString() === inputProductoItem.current.value.toString());
        console.log(indice)
        newItems.push({
            producto: {
                id: inputProductoItem.current.value,
                name: listaproductos.productos[indice].nombre ?? 'Sin Nombre',
            },
            cantidad: inputCantidadItem.current.value,
            precio: inputPrecioItem.current.value,
            precioM2: inputPrecioM2Item.current.value,
            descripcion: inputDescripcionItem.current.value
        })
        setItems(newItems)
        console.log(items)
    }

    function selectProduct(e) {
        console.log(e.target.value)
    }

    return (<>
        {isLoading && <Spiner />}
        <Cabecera titulo={'Nueva Cotización - Branding Studio'} />
        <section className="btns-header-base">
            <div className="columna-completa">
                <form onSubmit={onSubmit} className="cont-botones-base">
                    <div className='cont-title-info-coti'>
                        <p><b>NUEVA COTIZACIÓN PARA:&nbsp;</b><br />{watch('email')}</p>
                        <div className="cont-form-item">
                            <label htmlFor="nombreCliente">Nombre:</label>
                            <input type="text" placeholder="Nombre Cliente" {...register('nombreCliente')} />
                            <span>{!!errors['nombreCliente'] && errors['nombreCliente'].message}</span>
                        </div>
                        <div className="cont-form-item">
                            <label htmlFor="apellidoCliente">Apellido:</label>
                            <input type="text" placeholder="Apellido Cliente" {...register('apellidoCliente')} />
                            <span>{!!errors['apellidoCliente'] && errors['apellidoCliente'].message}</span>
                        </div>
                        <div className="cont-form-item">
                            <label htmlFor="empresa">Empresa:</label>
                            <input type="text" placeholder="Empresa" {...register('empresa')} />
                            <span>{!!errors['empresa'] && errors['empresa'].message}</span>
                        </div>
                        <span className="cotizacion-total">Total: {formatearMoneda(watch('total'))}</span>
                    </div>
                    <button className="btn-coti-guardar">Guardar</button>
                </form>
            </div>
        </section>
        <section className="cont-cotizacion">
            <div >
                <h2>Nuevo Item</h2>
                <div className="cont-btn-nuevo-item-coti">
                    <div className="cont-new-item-create">
                        <div className="cont-items-form">
                            <div className="cont-form-row">
                                <div className="cont-form-item">
                                    <label htmlFor="hostMail">Producto / Servicio:</label>
                                    <select ref={ inputProductoItem } id="tipo_producto" onChange={ e => selectProduct(e) }>
                                        <option value="0">Selecciona un producto o servicio</option>
                                        {
                                            listaproductos.productos?.map((item, index) => { return (<option key={index} value={item.id}>{item.nombre}</option>) })
                                        }
                                    </select>
                                </div>
                                <div className="cont-form-item">
                                    <label htmlFor="cantidad">cantidad:</label>
                                    <input ref={ inputCantidadItem } type="number" placeholder="1" id="cantidad" defaultValue={ '1' } />
                                </div>
                                <div className="cont-form-item">
                                    <label htmlFor="precio">Precio:</label>
                                    <input ref={ inputPrecioItem } type="number" placeholder="0" id="precio" defaultValue={ '0' } />
                                </div>
                                <div className="cont-form-item">
                                    <label htmlFor="precio_m2">Precio M2:</label>
                                    <input ref={ inputPrecioM2Item } type="number" placeholder="0" id="precio_m2" defaultValue={ '0' } />
                                </div>
                            </div>
                            <div className="cont-form-row">
                                <div className="cont-form-item w100">
                                    <label htmlFor="hostMail">Descripción de Item: {`(opcional)`}</label>
                                    <textarea ref={ inputDescripcionItem } placeholder="descripción de este Item"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cont-form-item'>
                        <canvas hidden id="canvas"></canvas>
                        <input hidden type="file" id="input-foto" accept="image/png, image/jpeg, image/webp" />
                        <div className='cont-prew-image-form-product'>
                            <div className='img-prodcuto-form' style={{ backgroundImage: `url("${ watch('imageProduct') === '' ? noImage : watch('imageProduct') }")` }} onClick={ () => addImage(1) }></div>
                        </div>
                    </div>
                    <button className="btn btn-primario" onClick={ addItemToList }>Agregar a cotización</button>
                </div>
                <div className="cont-items-cotizaciones-list">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                {
                                items?.map((item, index) => { return (<tr key={ index }>
                                    <td>{ index + 1 }</td>
                                    <td>{ item.producto.name }</td>
                                    <td>{ item.cantidad }</td>
                                    <td>{ item.precio }</td>
                                    {/*<td>{ item.precioM2 }</td>*/}
                                    <td>{ formatearMoneda(item.precio * item.cantidad) }</td>
                                </tr>
                                ) })
                                }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </>)
}