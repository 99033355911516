import { urlServer } from "../config/config";

export const getUserId = async credenciales => {

    let datosUser = {
        error: true,
    }

    let formData = new FormData();
    formData.append('user', credenciales.user);
    formData.append('pass', credenciales.pass);
    await fetch(`${ urlServer }/api/auth`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        console.log(data)
        if (data.ok) {
            datosUser['token'] = data.user.token
            datosUser['role'] = data.user.role
            datosUser.error = false
        }
    })
    .catch((error) => console.log(error))
    return datosUser
}

export const validToken = async token => {

    let isValid = false;

    let formData = new FormData();
    formData.append('token', token);
    await fetch(`${ urlServer }/api/valid-token`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        isValid = data.ok
    })
    .catch(error => {
        console.log(error)
        return isValid
    })
    return isValid
}

export const getMailsConfigUser = async token => {

    let datos = [];

    let formData = new FormData();
    formData.append('token', token);
    await fetch(`${ urlServer }/api/mail-config-user`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
        if (data.ok) {
            datos = data;
        } else {
            throw !!data.error ? data.error : 'ocurrio un error'
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const getClientsData = async (token, parametros={}) => {

    let datos = [];

    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/base/clientes`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const updateClients = async (parametros={}) => {

    let datos = [];

    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/base/clientes/actualizar`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}


export const delateClient = async (parametros={}) => {

    let datos = [];

    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/base/clientes/eliminar`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const delProduct = async (parametros={}) => {

    let datos = [];

    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/productos/eliminar`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const updatePrivado = async (parametros={}) => {

    let datos = [];

    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/base/clientes/privado`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const getProductos = async (token, parametros={}) => {

    let datos = [];

    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/productos/getProductos`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const getCotizaciones = async (token, parametros={}) => {

    let datos = [];

    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    await fetch(`${ urlServer }/api/cotizaciones/getCotizaciones`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const updateProducts = async (parametros={}) => {

    let datos = [];

    let formData = new FormData();
    for ( var key in parametros ) {
        if (key === "file1" || key === "file2" || key === "file3" || key === "file4" || key === "file5") {
            formData.append(key, parametros[key][0]);
        } else {
            formData.append(key, parametros[key]);
        }
    }
    await fetch(`${ urlServer }/api/productos/update`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        datos = data;
        if (!!datos.error) {
            if (datos.error === 'Expired token') {
                window.localStorage.removeItem('userData');
                window.location.reload();
                return
            }
        }
    })
    .catch(error => {
        console.log(error)
    })

    return datos
}

export const getDatos = async (ruta, parametros) => {
    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export const mutarDatos = async ( ruta, parametros ) => {
    const user = JSON.parse(localStorage.getItem('userData'))
    let token = user.token ?? ''
    
    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        if (key === "file1" || key === "file2" || key === "file3" || key === "file4" || key === "file5") {
            formData.append(key, parametros[key][0]);
        } else {
            formData.append(key, parametros[key]);
        }
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}