import { useQuery } from 'react-query';
import '../assets/css/popup-mail.css'
import Icons from './Icons'
import { getClientsData } from '../api/getUser';
import { useState } from 'react';
import Spiner from './Spiner';
import BtnsPages from './BtnsPages';

export default function SelectMailsPopup({ cancelar, mailsSelected, dataPrev }) {
    console.log(dataPrev)

    const [ listaSeleccionada, setListaSeleccionada ] = useState(dataPrev.mails.length === 0 ? [] : dataPrev.mails);
    const [ listaExcluidos, setListaExcluidos ] = useState(dataPrev.mails.length === 0 ? [] : dataPrev.mails)
    const [ seleccionarTodos, setSeleccionarTodos ] = useState(dataPrev.type === 'exluded')

    const [ numMostrar, setNumMostrar ] = useState(20)
    const [ inicio, setInicio ] = useState(0)
    const [ buscarB, setBuscarB ] = useState('')
    const [ pagActiva, setPagActiva ] = useState(1)
    const user = JSON.parse(localStorage.getItem('userData'))
    const { data = { clientes: [], total: 0 }, isLoading } = useQuery(["clientes", [numMostrar, inicio, buscarB]], () => getClientsData(user.token, { limit: numMostrar, inicio: inicio, buscar: buscarB }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    function buscar(valor) {
        setPagActiva(1)
        setInicio(0)
        setBuscarB(valor)
    }

    function selectMail(id, status) {
        if (status) {
            setListaSeleccionada([...listaSeleccionada, id])
            setListaExcluidos(listaExcluidos.filter((item) => item !== id))
        } else {
            setListaSeleccionada(listaSeleccionada.filter((item) => item !== id))
            setListaExcluidos([...listaExcluidos, id])
        }
    }

    function clickPagina(p) {
        setInicio(numMostrar * (p-1))
        setPagActiva(p)
    }

    const idSelected = id => {
        if (seleccionarTodos) {
            return !listaExcluidos.includes(id)
        } else {
            return listaSeleccionada.includes(id)
        }
    }

    function marcarTodos(marcar) {
        setSeleccionarTodos(marcar)
        setListaSeleccionada([])
        setListaExcluidos([])
    }

    function setearMails() {
        mailsSelected({
            mails: seleccionarTodos ? [ ...listaExcluidos ] : [ ...listaSeleccionada ],
            type: seleccionarTodos ? 'exluded' : 'selected', //exluded
            totalBase: data.total
        })
    }

    return <div className="popup-email-select">
        { isLoading && <Spiner/> }
        <div className="popup-email-container">
            <div className="popup-email-header">
                <div className="popup-email-select__header">
                    <h2 className="popup-email-select__title">Selecciona los correos</h2>
                    <button className="popup-email-bton-close" onClick={ cancelar }>X</button>
                </div>
                <div className='popup-email-header-actions'>
                    <div className='popup-marcar-todos-email'>
                        <input type='checkbox' defaultChecked={ seleccionarTodos } onChange={ e => marcarTodos(e.target.checked) } />
                        <label>Marcar todos: { data.total } correos</label>
                    </div>
                    <div className='cont-buscar'>
                        <Icons req={ 'buscar' }/>
                        <input className='popup-email-search' type='text' placeholder='Buscar' onChange={ e => buscar(e.target.value) }/>
                    </div>
                </div>
            </div>
            <div className="popup-email-body">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Correo</th>
                            <th>Seleccionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data && data?.clientes.map((cliente, index) => <tr key={ index }>
                            <td width={ '20px' }>{ cliente.id }</td>
                            <td>{ cliente.correo }</td>
                            <td width={ '20px' }>
                                <input type='checkbox' className='popup-email-select__checkbox' defaultChecked={ idSelected(cliente.id) } checked={ idSelected(cliente.id) } onChange={ e => selectMail(cliente.id, e.target.checked) } />
                            </td>
                        </tr> ) }
                    </tbody>
                </table>
            </div>
            <div className="popup-email-footer">
                <div className="cont-footer-table-op">
                    <div id="btns-pages" className="cont-btns-pages container f-iz">
                        { !!data && <BtnsPages numMostrar={ numMostrar } pagActiva={ pagActiva } setPagActiva={ (p) => clickPagina(p) } totalP={ data.total } /> }
                    </div>
                    <div className="f-dr">
                        { !!data && <p>Mostrando { data.clientes.length } clientes de { data.total } en { Math.ceil(data.total / numMostrar) } páginas</p> }
                    </div>
                </div>
                <button className='pp-mail-btn btn-azul btn-add' onClick={ setearMails }>Agregar seleccionados: { seleccionarTodos ? (data.total - listaExcluidos.length) : listaSeleccionada.length } correos</button>
            </div>
        </div>
    </div>
}